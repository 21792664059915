import axios from "@/plugins/axios-setting";
import {
  CreateTimesheetInterface,
  TimesheetInterface,
  TimesheetPaginationInterface,
  TimesheetProjectPaginationInterface,
  TimesheetReportInterface,
  TimesheetReportRequestInterface,
} from "@/types/timesheet.type";
import { DataTableOption } from "@/types/data-table.type";
import { formatOrdering } from "@/utils/datatableUtils";

export function getTimesheets(): Promise<TimesheetInterface[]> {
  return axios.get("/api/time-sheet/");
}

export function getUserTimesheets(
  userId: number,
  dateRange: string[],
  projects: number[],
  options?: DataTableOption
): Promise<TimesheetInterface[]> {
  const newOptions = new URLSearchParams();
  if (options) {
    newOptions.append("itemsPerPage", String(options.itemsPerPage));
    newOptions.append("page", String(options.page));
    newOptions.append("ordering", formatOrdering(options));
  }

  if (dateRange.length > 1) {
    newOptions.append("date_after", dateRange[0]);
    newOptions.append("date_before", dateRange[1]);
  }

  newOptions.append("user", String(userId));

  for (const proj of projects) {
    newOptions.append("project", String(proj));
  }

  return axios.get("/api/time-sheet/", { params: newOptions });
}

export function createTimesheet(data: CreateTimesheetInterface): Promise<TimesheetInterface> {
  return axios.post("/api/time-sheet/", data);
}

export function deleteTimesheet(timesheetId: number): Promise<TimesheetInterface> {
  return axios.delete(`/api/time-sheet/${timesheetId}/`);
}

export function getTimesheetReport(filterData: TimesheetReportRequestInterface): Promise<TimesheetReportInterface> {
  return axios.post(`/api/time-sheet/reports/`, filterData);
}

export function getTimeSheetById(id: number): Promise<TimesheetInterface> {
  return axios.get(`/api/time-sheet/${id}/`);
}

export function updateTimeSheet(data: TimesheetInterface): Promise<TimesheetInterface> {
  return axios.put(`/api/time-sheet/${data.id}/`, data);
}

export function getTimesheetsByProject(
  options: TimesheetProjectPaginationInterface
): Promise<TimesheetPaginationInterface> {
  const newOptions = new URLSearchParams();
  if (options.options) {
    newOptions.append("itemsPerPage", String(options.options.itemsPerPage));
    newOptions.append("page", String(options.options.page));
    newOptions.append("ordering", formatOrdering(options.options));
  }

  if (options.search) {
    newOptions.append("search", String(options.search));
  }

  if (options.status && options.status !== "AL") {
    newOptions.append("status", String(options.status));
  }

  newOptions.append("project", String(options.id));

  return axios.get("/api/time-sheet/", { params: newOptions });
}

export function updateTimesheetsStatus(ids: number[], status: string) {
  return axios.post("/api/time-sheet/update_status/", {
    ids,
    status,
  });
}
