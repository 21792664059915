




























































































































































import { Component, Emit, Mixins, Prop, Vue, Watch } from "vue-property-decorator";
import { TimesheetInterface } from "@/types/timesheet.type";
import { TimesheetModule } from "@/store/modules/timesheet-module";
import { DurationTypeInterface } from "@/types/durationType.type";
import { ProjectListInterface, ProjectTagInterface } from "@/types/project.type";
import { ProjectModule } from "@/store/modules/project-module";
import DayOffModule from "@/store/modules/day-off-module";
import { DayOffInterface } from "@/types/day-off.type";
import { ProjectMemberModule } from "@/store/modules/project-member-module";
import { RoleMixin } from "@/mixins/role-mixin";
import TagForm from "@/components/project/TagForm.vue";
import { UserModule } from "@/store/modules/user-module";

@Component({
  components: {
    TagForm,
  },
})
export default class TimeSheetForm extends Mixins(RoleMixin) {
  @Prop({ required: false, default: false })
  public admin: boolean | undefined;

  get userSelectableProjects(): ProjectListInterface[] {
    return ProjectModule.projectList;
  }

  get isManager(): boolean {
    return ProjectMemberModule.isManager;
  }

  private get dayOffs(): DayOffInterface[] {
    return DayOffModule.dayOffs;
  }

  @Prop({
    type: Boolean,
    default: false,
  })
  private readonly isTimesheetPage!: boolean;

  @Prop({
    type: [Number, String],
    default: null,
  })
  private readonly timeSheetId!: number | null | string;

  @Prop({
    type: Function,
  })
  private afterSubmit!: (success: boolean, msg: string) => void;

  @Prop({
    type: Array,
    default: null,
  })
  private durationTypes!: DurationTypeInterface[];

  private taskDescriptionRules = [(v: any) => !!v || "Task description is required"];

  private projectRules = [(v: any) => !!v || "Project is required"];

  private durationRules = [(v: any) => !!v || "Duration is required"];

  private timeSheetData: TimesheetInterface = {
    id: -1,
    taskDescription: "",
    date: "",
    billable: false,
    duration: 0,
    project: 0,
    user: 0,
    projectTags: [],
    lock: false,
    client: -1,
    canEdit: false,
    status: "PD",
  };

  private menuTimeSheet: boolean = false;
  private formValid: boolean = false;

  @Emit("close-edit-dialog")
  public async closeDialog() {
    await this.setClient();
    return false;
  }

  @Watch("timeSheetId")
  private async onPropertyChanged(value: number | string) {
    await this.setClient();
  }

  private async created() {
    await ProjectModule.getProjectList(!this.admin);
    await this.setClient();
  }

  private async setClient() {
    await TimesheetModule.getTimeSheetById(this.timeSheetId as number);
    this.timeSheetData = {
      ...TimesheetModule.timesheetData,
    };
    this.timeSheetData.projectTags = (this.timeSheetData.projectTags as ProjectTagInterface[]).map(
      (item) => item.projectTagId
    );
  }

  private updateSelectedTag(value: any) {
    this.timeSheetData.projectTags = value;
  }

  private removeTag(item: any) {
    const index = this.timeSheetData.projectTags.indexOf(item.projectTagId);
    if (index >= 0) {
      this.timeSheetData.projectTags.splice(index, 1);
    }
  }

  private allowCreateTimesheetDate(date: string): boolean {
    return !this.dayOffs.some((value) => value.date === date);
  }

  private async updateTimesheet() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.formValid) {
      try {
        await TimesheetModule.updateTimeSheetDetail(this.timeSheetData);
        this.afterSubmit(true, "Edit timesheet successfully!");
      } catch (e) {
        this.afterSubmit(false, e.response.data.detail);
      }
    }
  }

  get currentProjectSelected(): ProjectListInterface | undefined {
    return this.userSelectableProjects.find((data) => {
      return data.id === this.timeSheetData.project;
    });
  }

  get userSelectableTags(): ProjectTagInterface[] {
    return this.currentProjectSelected?.projectTags || [];
  }

  get isEmployee(): boolean {
    return UserModule.isEmployee;
  }

  @Watch("timeSheetData.project")
  private resetTag(_: number, oldVal: number) {
    if (oldVal !== 0) {
      this.timeSheetData.projectTags = [];
    }
  }

  get isBillable(): boolean {
    if (this.admin) {
      return true;
    }
    if (!this.timeSheetData || !(this.timeSheetData as TimesheetInterface).project) {
      return false;
    }

    const proj = this.currentProjectSelected;

    if (!proj) {
      return false;
    }

    return proj.billable;
  }
}
