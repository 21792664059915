import { Action, getModule, Module, Mutation, MutationAction, VuexModule } from "vuex-module-decorators";
import {
  CreateTimesheetInterface,
  TimesheetInterface,
  TimesheetPaginationInterface,
  TimesheetProjectPaginationInterface,
  UserTimesheetFilterInterface,
} from "@/types/timesheet.type";
import {
  getTimesheets,
  getUserTimesheets,
  createTimesheet,
  deleteTimesheet as delTimesheet,
  getTimeSheetById,
  updateTimeSheet as updateTimeSheetDetail,
  getTimesheetsByProject,
} from "@/services/timesheet.service";
import store from "@/store";
import { UserModule } from "@/store/modules/user-module";

interface ITimesheetModule {
  timesheets: TimesheetInterface[];
  userTimesheets: TimesheetInterface[];
  timesheetData: TimesheetInterface;
}

@Module({
  dynamic: true,
  name: "timesheet",
  store,
})
class Timesheet extends VuexModule {
  public timesheets: TimesheetInterface[] = [];
  public totalTimesheet: number = 0;
  public userTimesheets: TimesheetInterface[] = [];
  public timesheetData: TimesheetInterface = {} as TimesheetInterface;
  public timesheetsByProject: TimesheetInterface[] = [];

  @MutationAction({ mutate: ["timesheets"] })
  public async getTimesheets() {
    const timesheets: TimesheetInterface[] = await getTimesheets();
    return { timesheets };
  }

  @MutationAction({ mutate: ["timesheetData"] })
  public async getTimeSheetById(id: number) {
    const { ...timesheetData }: TimesheetInterface = await getTimeSheetById(id);
    return { timesheetData };
  }

  @MutationAction({ mutate: ["timesheetData", "userTimesheets"] })
  public async updateTimeSheetDetail(timesheet: TimesheetInterface) {
    const updatedTimeSheet: TimesheetInterface = await updateTimeSheetDetail(timesheet);
    const userTimesheets: TimesheetInterface[] = [...(this.state as ITimesheetModule).userTimesheets];
    const updatedIndex: number = userTimesheets.findIndex((item) => item.id === updatedTimeSheet.id);
    userTimesheets[updatedIndex] = updatedTimeSheet;
    return { timesheetData: updatedTimeSheet, userTimesheets };
  }

  @Action({ commit: "updateUserTimesheet" })
  public async getUserTimesheets(data: UserTimesheetFilterInterface) {
    let userTimesheets: TimesheetInterface[] = [];
    userTimesheets = await getUserTimesheets(
      UserModule.userProfile.realId,
      data.dateRange,
      data.projects,
      data.options
    );
    return userTimesheets;
  }

  @Action({ commit: "updateTimesheet", rawError: true })
  public async addTimesheet(timesheet: CreateTimesheetInterface) {
    return createTimesheet(timesheet);
  }

  @MutationAction({ mutate: ["timesheets", "userTimesheets"] })
  public async deleteTimesheet(id: number) {
    await delTimesheet(id);
    const newTimesheet = [...(this.state as ITimesheetModule).timesheets]; // Clone timesheet
    const newUserTimesheet = [...(this.state as ITimesheetModule).userTimesheets]; // Clone timesheet
    const deletedIndex: number = newTimesheet.findIndex((item) => item.id === id);
    const deletedUserIndex: number = newUserTimesheet.findIndex((item) => item.id === id);
    newTimesheet.splice(deletedIndex, 1);
    if (deletedUserIndex !== -1) {
      newUserTimesheet.splice(deletedUserIndex, 1);
    }
    return { timesheets: newTimesheet, userTimesheets: newUserTimesheet };
  }

  @MutationAction({ mutate: ["timesheetsByProject", "totalTimesheet"], rawError: true })
  public async getTimesheetsByProject(options: TimesheetProjectPaginationInterface) {
    try {
      const timesheetsByProject = await getTimesheetsByProject(options);
      return { timesheetsByProject: timesheetsByProject.results, totalTimesheet: timesheetsByProject.count };
    } catch {
      return { timesheetsByProject: [], totalTimesheet: 0 };
    }
  }

  @Mutation
  private updateTimesheet(timesheet: TimesheetInterface) {
    this.timesheets.push(timesheet);
    this.userTimesheets.push(timesheet);
    this.totalTimesheet = this.userTimesheets.length;
  }

  @Mutation
  private updateUserTimesheet(userTimesheets: TimesheetPaginationInterface) {
    this.userTimesheets = userTimesheets.results;
    this.totalTimesheet = userTimesheets.count;
  }
}

export const TimesheetModule = getModule(Timesheet);
