






















































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { DataTableHeader } from "vuetify";

import { ProjectModule } from "@/store/modules/project-module";
import { TimesheetModule } from "@/store/modules/timesheet-module";
import { ProjectListInterface, ProjectTagInterface } from "@/types/project.type";
import { CreateTimesheetInterface, TimesheetInterface } from "@/types/timesheet.type";
import { SnackbarModule } from "@/store/modules/snackbar-module";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { mixins } from "vue-class-component";
import { UserModule } from "@/store/modules/user-module";
import DayOffModule from "@/store/modules/day-off-module";
import moment from "moment";
import { DayOffInterface } from "@/types/day-off.type";
import TimeSheetForm from "@/components/time-sheet/TimeSheetForm.vue";
import { DurationTypeModule } from "@/store/modules/duration-module";
import { DurationTypeInterface } from "@/types/durationType.type";
import TableComponent from "@/components/TableComponent.vue";
import ConfirmationDialog from "@/components/utils/ConfirmationDialog.vue";
import { DataTableOption } from "@/types/data-table.type";
import TagForm from "@/components/project/TagForm.vue";

@Component({
  components: {
    ConfirmationDialog,
    TimeSheetForm,
    TableComponent,
    TagForm,
  },
})
export default class TimesheetList extends mixins(DurationHelperMixins) {
  get durationTypes(): DurationTypeInterface[] {
    return DurationTypeModule.durationTypes;
  }

  private headers: DataTableHeader[] = [
    {
      text: "Lock",
      value: "lock",
    },
    {
      text: "Approved",
      value: "active",
    },
    {
      text: "Date",
      value: "date",
    },
    {
      text: "Billable",
      value: "billable",
    },
    {
      text: "Description",
      value: "taskDescription",
      width: "30%",
      sortable: false,
    },
    {
      text: "Projects",
      value: "projectDisplay",
      width: "20%",
      sortable: false,
    },
    {
      text: "Tags",
      value: "projectTags",
      sortable: false,
      width: "13%",
    },
    {
      text: "Duration",
      value: "duration",
      width: "10%",
    },
    {
      text: "Action",
      value: "action",
      width: "10%",
      align: "center",
      sortable: false,
    },
  ];

  private filterDateRange: string[] = [];
  private dateRangeOpen: boolean = false;
  private menu: boolean = false;
  private valid: boolean = false;
  private filterProjects: number[] = [];
  private timeSheetDialog: boolean = false;
  private timeSheetId: number = 0;

  private taskDescriptionRules = [(v: any) => !!v || "Task description is required"];

  private projectRules = [(v: any) => !!v || "Project is required"];

  private durationRules = [(v: any) => !!v || "Duration is required"];

  private dateRules = [(v: any) => v.includes("~") || v.length === 0 || "You must select Start date and End date"];

  private selectTimesheetId: number = -1;
  private defaultTimesheetFields: CreateTimesheetInterface = {
    taskDescription: "",
    date: new Date().toISOString().substr(0, 10),
    billable: false,
    duration: 0,
    project: 0,
    projectTags: [],
  };

  private options?: DataTableOption;

  private currentTimesheet: CreateTimesheetInterface = Object.assign({}, this.defaultTimesheetFields); // copy

  private async mounted() {
    await ProjectModule.getProjectList(true);
    await DurationTypeModule.getDurationTypes();
    await DayOffModule.getDayOffs(moment().format("YYYY-MM"));
    this.filterTimesheet();
  }

  private filterTimesheet() {
    this.filterDateRange.sort((a: string, b: string): number => Number(new Date(a)) - Number(new Date(b)));

    TimesheetModule.getUserTimesheets({
      dateRange: this.filterDateRange,
      projects: this.filterProjects,
      options: this.options,
    });
  }

  private optionChanged(newOptions: DataTableOption) {
    this.options = newOptions;
    this.filterTimesheet();
  }

  get timesheetCount(): number {
    return TimesheetModule.totalTimesheet;
  }

  get timesheets(): TimesheetInterface[] {
    return TimesheetModule.userTimesheets.sort((a: TimesheetInterface, b: TimesheetInterface): number =>
      Number(Number(new Date(b.date)) - Number(new Date(a.date)))
    );
  }

  get projects(): ProjectListInterface[] {
    return ProjectModule.projectList;
  }

  get isGuest(): boolean {
    return UserModule.userProfile.role.name.toLowerCase() === "guest";
  }

  get isEmployee(): boolean {
    return UserModule.isEmployee;
  }

  get currentProjectTags(): ProjectTagInterface[] {
    return this.projects.find((p) => p.id === this.currentTimesheet.project)?.projectTags || [];
  }

  private setCurrentSelectTimesheet(id: number) {
    this.selectTimesheetId = id;
  }

  private async createTimesheet() {
    (this.$refs.form as Vue & { validate: () => boolean }).validate();
    if (this.valid) {
      try {
        await TimesheetModule.addTimesheet(this.currentTimesheet);
      } catch (e) {
        SnackbarModule.setSnack({
          color: "error",
          message: e.response.data.detail,
        });
        return;
      }
      SnackbarModule.setSnack({
        color: "success",
        message: "Add timesheet successfully!",
      });

      // reset timesheet fields
      this.currentTimesheet = Object.assign({}, this.defaultTimesheetFields);
      (
        this.$refs.form as Vue & {
          resetValidation: () => boolean;
        }
      ).resetValidation();
    } else {
      SnackbarModule.setSnack({
        color: "error",
        message: "Please fill in all the fields",
      });
    }
  }

  private async deleteTimesheet() {
    try {
      await TimesheetModule.deleteTimesheet(this.selectTimesheetId);
    } catch (exp) {
      const error = exp.toJSON();
      await SnackbarModule.setSnack({
        color: "error",
        message: error.message,
      });
      return;
    }
    await SnackbarModule.setSnack({
      color: "success",
      message: "Delete timesheet successfully!",
    });
  }

  get dateRangeText(): string {
    const sortedData = [...this.filterDateRange].sort(
      (a: string, b: string): number => Number(new Date(a)) - Number(new Date(b))
    );

    return sortedData.join(" ~ ");
  }

  get isBillable(): boolean {
    if (!this.currentTimesheet && !(this.currentTimesheet as CreateTimesheetInterface).project) {
      return false;
    }

    if (!this.projects) {
      return false;
    }

    const proj = this.projects.filter((dat) => {
      return dat.id === this.currentTimesheet.project;
    });

    if (!proj || proj.length === 0) {
      return false;
    }

    return proj[0].billable;
  }

  private get dayOffs(): DayOffInterface[] {
    return DayOffModule.dayOffs;
  }

  private allowCreateTimesheetDate(date: string): boolean {
    return !this.dayOffs.some((value) => value.date === date);
  }

  private openTimeSheetDialog(id: number) {
    this.timeSheetDialog = true;
    this.timeSheetId = id;
  }

  private closeDialog(success: boolean, msg: string) {
    if (success) {
      SnackbarModule.setSnack({
        color: "success",
        message: msg,
      });
    } else {
      SnackbarModule.setSnack({
        color: "error",
        message: msg,
      });
    }
    this.timeSheetDialog = false;
  }

  private clickedRow(data: any) {
    if (!data.lock && data.canEdit) {
      this.openTimeSheetDialog(data.id);
    }
  }

  private clickedDelete(data: TimesheetInterface) {
    const id: number = data.id || -1;
    this.setCurrentSelectTimesheet(id);
    (
      this.$refs.deleteDialog as Vue & {
        openDialog: () => boolean;
      }
    ).openDialog();
  }

  private clearDateRange() {
    this.filterDateRange = [];
    this.filterTimesheet();
  }

  private addRowClasses(item: TimesheetInterface) {
    if (item.lock) {
      return "disable-row";
    }
  }

  private removeTag(item: any) {
    const index = this.currentTimesheet.projectTags.indexOf(item.projectTagId);
    if (index >= 0) {
      this.currentTimesheet.projectTags.splice(index, 1);
    }
  }

  private updateSelectedTag(value: any[]) {
    this.currentTimesheet.projectTags = value;
  }

  private closeEditDialog(isClose: boolean) {
    this.timeSheetDialog = isClose;
    setTimeout(() => {
      const refTimesheet = this.$refs.timesheetForm as Vue & {
        $refs: () => HTMLFormElement;
      };
      const resetAllValidation = refTimesheet.$refs.form as Vue & {
        resetValidation: () => boolean;
      };
      resetAllValidation.resetValidation();
    }, 300);
  }

  @Watch("currentTimesheet.project")
  private onProjectSelect(value: number) {
    this.currentTimesheet.projectTags = [];
    const proj = this.projects.filter((dat) => {
      return dat.id === value;
    });

    if (proj && proj.length > 0 && proj[0].billable) {
      this.currentTimesheet.billable = true;
    } else {
      this.currentTimesheet.billable = false;
    }
  }
}
